.project-details {
    .projet-detail-wrap {
        width: 100%;
        margin: auto;

        .project-info {
            width: 85%;
            margin: auto;

            h2 {
                margin: 100px 0 50px 0;
            }

            .project-info-group {
                border: 1px solid #A3a3a3;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 20px;
                border-radius: 10px;

                .proj-row {
                    display: flex;
                    margin: 5px 0;

                    label {
                        min-width: 200px;
                        text-align: left;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 18px;
                    }

                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 18px;
                        padding-left: 10px; 
                    }
                }
            }
        }

        .project-content {
            width: 85%;
            margin: auto;
            text-align: left;
            padding: 50px 0;

            p {
                font-weight: 500;
                font-size: 16px;
            }

            h3 {
                margin: 50px 0;
            }
        }

        .project-suggest {
            width: 100%;
            margin-top: 70px;

            .list-project {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 20px 2%
            }
        }
    }
}