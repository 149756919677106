.forum_detail {

  &_posts {
    width: 90%;
    margin: 80px auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;

    .forum_detail_posts_left {
      border: 1px;
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 20px;

      .posts_left_title {
        display: flex;

        .title {
          width: 50%;
          font-weight: 700;
          font-size: 24px;
          text-align: left;
        }

        .posts_left_title_interactive {
          display: flex;
          width: 50%;
          justify-content: right;

          .love {

          }

          .share {
            margin-left: 10px;
          }
        }
      }

      .posts_left_info {
        display: flex;

        p {
          margin: 10px;
        }
      }
    }

    .forum_detail_posts_right {
      border: 1px;
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 20px;
    }
  }
}
