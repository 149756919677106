.design_service_card {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  padding: 15px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto;

  .design_service_img {

  }

  .design_service_content {
    text-align: left;
    margin-left: 20px;

    h4 {
      font-weight: 700;
      font-size: 20px;
    }

    p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      color: #838383;
    }

    .design_service_button {
      background: linear-gradient(93.58deg, #F4B309 0%, #FFE600 100%);
      color: #FFFFFF;
      margin: 0;
      padding: 5px 20px;
      border-radius: 10px;
      border: none;
      font-weight: 700;
      font-size: 16px;
    }
  }
}