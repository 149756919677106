// .change-password {
//   margin-right: 25px;
//   &-btn {
//     float: left;
//     background-color: var(--orange-home);
//     height: 50px;
//     width: 120px;
//     cursor: pointer;
//     border-radius: 4px;
//     text-align: center;
//     margin-right: 20px;
//     padding-top: 10px;
//     font-size: 18px;
//     font-weight: 500;
//     color: white;
//     &:hover {
//       background-color: var(--orange);
//     }
//   }
// }

// .MuiBox-root {
//   .box-main-login-normal {
//     display: flex;
//     justify-content: flex-end;
//     .box-main-login-cancel {
//       background: #dadada;
//       cursor: pointer;
//       margin-right: 13px;
//       height: 40px;
//       width: 86px;
//       font-weight: 500;
//       font-size: 16px;
//       text-align: center;
//       padding-top: 7px;
//       border-radius: 6px;
//     }
//     .box-main-login-approve {
//         background-color: var(--orange-home);
//     }
//   }
//   .box-main-login {
//     // height: 100px;
//     width: 100%;
//     height: 100%;
//     // background-color: rgb(243, 221, 221);
//     margin-top: -25px;
//     padding: 0px 5%;
//     .box-main-login-social {
//       display: flex;
//       justify-content: space-around;
//       align-items: center;
//     }
//     .box-main-title {
//       p {
//         font-size: 20px;
//         font-weight: 500;
//       }
//       width: 100%;
//       height: 25px;
//       display: flex;
//       // justify-content: center;
//       // border-bottom: 2px solid #C4C4C4;
//     }
//     .box-main-form {
//       input {
//         width: 100%;
//         padding-left: 15px;
//         height: 40px;
//         border: 1px solid #c4c4c4;
//         border-radius: 5px;
//       }
//       label {
//         margin: 20px 0px 5px 0px;
//         // color: white;

//       }
//       .box-main-form-forget {
//         color: var(--orange-home);
//         // margin-top: 40px;
//       }
//     }
//     .box-main-or {
//       text-align: center;
//       font-size: 16px;
//       font-weight: 600;
//       margin-top: 20px;
//     }
//   }
// }

.change_password {

  .form {
    width: 100%;
    padding: 100px 0;

    .form_box {
      width: 45%;
      position: relative;
      left: 50%;
      padding: 20px;
      transform:translateX(-50%);
      border: 2px solid #F4B309;
      border-radius: 20px;
      margin-bottom: 100px;

      .link {
        text-align: left;

        span {
          font-size: 18px;
          color: #F4B309;
          margin-top: 5px;
          position: relative;
          top: 2px;
          left: 4px;
        }
      }

      .title {
        h3 {
          font-weight: 700;
          font-size: 32px;
          margin: 30px 0;
        }
      }

      .content {
        width: 65%;
        margin: auto;

        .content_child {
          display: flex;
          text-align: left;
          padding: 10px;
          width: 70%;
          margin: auto;
          border-bottom: 2px solid #F4B309;

          input {
            width: 100%;
            border: none;
            padding: 0 20px;
            font-size: 20px;
            outline: none;
          }
        }
      }

      .button {
        background-color: #F4B309;
        color: #FFFFFF;
        width: 18%;
        border: none;
        border-radius: 13px;
        padding: 10px 0px;
        margin: 30px 0 60px 0;
      }
    }
  }
}
