.posts {
  width: 90%;
  margin: 80px auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  &_left {
    border: 1px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;

    .title {
      font-weight: 700;
      font-size: 24px;
      color: #F4B309;
      margin-bottom: 35px;
    }

    .posts_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .posts_card {
        display: flex;
        width: calc(100% / 2 - 15px);
        text-align: left;
        margin-bottom: 15px;

        .posts_card_content {
          margin-left: 12px;
        
          h4 {
            font-weight: 700;
            font-size: 18px;
          }

          .posts_card_content_text {
            display: flex;

            p {
              font-weight: 500;
              font-size: 14px;
              padding-top: 8px;
            }
          }
        }
      }
    }
  }


  &_right {
    border: 1px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;

    .title {
      font-weight: 700;
      font-size: 24px;
      color: #F4B309;
      margin-bottom: 35px;
    }

    .posts_box {

      .posts_card {
        display: flex;
        text-align: left;
        margin-bottom: 15px;

        .posts_card_content {
          margin-left: 12px;
          h4 {
            font-weight: 700;
            font-size: 18px;
          }

          .posts_card_content_text {
            display: flex;

            p {
              font-weight: 500;
              font-size: 14px;
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
}