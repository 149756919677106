.design_content {
  width: 85%;
  margin: 50px auto;
  border: 1px solid #A3a3a3;
  padding: 20px;
  border-radius: 8px;

  .design_content_child {
    display: flex;
    align-items: flex-start;
    border-radius: 10px;

    label {
      min-width: 200px;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      padding-left: 10px;
    }
  }
}