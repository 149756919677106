.nav{
  z-index: 1000;
  position: fixed;
  width: 20.8%;
  height: 100%;
  background-color: #006889;
  flex-direction: column;
  align-content: center;
  .link{
    color: #fff;
  }

}