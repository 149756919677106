
.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
  padding: 10px;
}

.menu-items a {
  display: block;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
}

.menu-items > a:hover,
.menu-items:hover > a {
  background-color: none;
  color: #F4B309 !important;
  transition: 0.1s;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 5;
  top: 49px;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 12rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}
