.button-for {
  width: 100%;
  margin: auto;
  padding: 50px 0;

  .button-for-name {
    position: relative;

    .ant-divider-inner-text {
      display: inline-block;
      padding: 0 1em;
      font-size: 24px;
      font-weight: 500;
      color: #2F0565;
    }
    // justify-content: space-between;
    // align-items: center;
    // .button-for-tilte {
    //   font-size: 30px;
    //   font-weight: 500;
    //   display: inline;
    //   padding: 0 20px;
    // }
    // .line {
    //   position: absolute;
    //   top: 24px;
    // }
    // .button-for-btn {
    //   display: flex;
    //   .button-for-btn-right {
    //     cursor: pointer;
    //     height: 32px;
    //     width: 42px;
    //     margin-left: 15px;
    //     text-align: center;
    //     border: 1px solid var(--orange-home);
    //     border-radius: 5px;
    //     // img {
    //     //   padding-top: 2px;
          
    //     // }
    //     .button-for-btn-white {
    //       display: none;
    //       margin: auto;
    //       display: flex;
    //       margin-top: 7px;
    //     }
    //   }
    //   .button-for-btn-right:hover {
    //     background-color: var(--orange-home);
    //     .button-for-btn-white  {
    //         display: block;
    //       }
    //       .button-for-btn-color {
    //         display: none;
    //       }
    //   }
    // }
  }
}

.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #A3A3A3;
}