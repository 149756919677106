.material_build {
  width: 100%;

  .material_build_ele {
    width: 85%;
    margin: auto;
    h3 {
      color: #F4B309;
      margin-bottom: 20px;
    }
  }
}