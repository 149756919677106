.home-backgroundImg {
  width: 100%;
  height: 500px;
  // height: calc(100vh - 145px);
  object-fit: fill;
}
.homesearch {
  width: 100%;
  // height: 680px;
  // background-image: url("../../../assets/images/home/backgroundimage.png");
  object-fit: fill;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  &-list {
    margin: auto;
    position: absolute;
    top: 60%;
    left: 10%;
  }
  &-btn {
    display: block;
    background: rgba(255, 255, 255, 0.75);
    top: 0;
    left: 0;

    max-width: 70%;
    
    p {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      color: #212121;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #212121;
    }
  }
  a {
    color: white;
  }
  &-list-title {
    color: white;
    font-size: 40px;
    font-weight: 500;
    opacity: 0.8;
  }
  &-list-category {
    width: 1061px;
    display: flex;
    width: 90%;
    margin: auto;
    font-size: 18px;
    margin-top: 35px;
    font-weight: 500;
    // justify-content: space-around;
    justify-content: center;
    padding-left: 70px;
  }
  //add
  &-category-text {
    padding-right: 70px;
  }
}
