.search {
  display: flex;
  height: 60px;
  margin: auto;
  background-color: white;
  border-radius: 30px;
  // width: 90%;
  // width: 1061px;
  width: 95%;
  // border:2px solid var(--orange-home);

  .btn:hover {
    color: black;
    border: none;
  }

  .btn:active {
    color: black;
    border: none;
  }

  .dropdown {
    height: 100%;
    width: 100%;
    #dropdown-basic {
      height: 100%;
      font-size: 17px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      // border-radius: 55px;
      width: 100%;
      padding-left: 35px;
      padding-right: 35px;
      color: black !important;
    }
    .dropdown-menu {
      a {
        color: black !important;
      }
    }
  }
  .search-input {
    color: black;
    width: 100%;
    outline: none;
    input {
      width: 100%;
      height: 100%;
      padding-left: 20px;
      border: none;
      font-size: 17px;
      border-left: 1px solid rgba(147, 140, 140, 0.25);

      &:hover {
        outline: none;
      }
    }

    input::placeholder {
      margin-left: 10px;
    }
  }
  .search-btn {
    height: 100%;
    background-color: var(--orange-home);
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    // border-radius: 25px;
    z-index: 999;

    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    // height: 10px;
    img {
      padding-top: 7px;
    }
  }
}
