.fu_detail {

  .fu_detail_box {
    width: 85%;
    margin: auto;
    padding: 20px;
    border: 1px solid #A3A3A3;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .fu_detail_same {
    margin: 70px 0;
    
    h3 {
      margin: 40px 0;
    }
  }
}