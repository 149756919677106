.design-service-box {
  width: 85%;
  margin: auto;
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
}