.loginnormal{
    height: 40px;
    width: 100%;
    background: linear-gradient(93.58deg, #F4B309 0%, #FFE600 100%);
    color:white;
    font-size: 18px;
    margin-right: 35px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 8px;
    text-align: center;
    padding: 7px 15px;
}