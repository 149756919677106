.forgot_password {


  .content {
    width: 100%;
    padding: 100px 0;

    .content_box {
      width: 45%;
      position: relative;
      left: 50%;
      transform:translateX(-50%);
      border: 2px solid #F4B309;
      padding: 35px;
      border-radius: 20px;

      .content_box_link {
        text-align: left;

        span {
          font-size: 18px;
          color: #F4B309;
          margin-top: 5px;
          position: relative;
          top: 2px;
          left: 4px;
        }
      }

      .content_box_title {
        h3 {
          font-weight: 700;
          font-size: 32px;
          margin: 30px 0;
        }
      }

      .content_box_form {
        display: flex;
        text-align: left;
        padding: 10px;
        width: 70%;
        margin: auto;
        border-bottom: 2px solid #F4B309;

        input {
          width: 100%;
          border: none;
          padding: 0 20px;
          font-size: 20px;
        }

        input:focus {
          outline: none;
        }

      }

      p {
        width: 70%;
        padding: 15px 0;
        margin: auto;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
      }

      button {
        border: none;
        padding: 10px 25px;
        background-color:#f6b204;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
}