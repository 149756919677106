.register {
  
  .register_content {
    width: 100%;
    padding: 100px 0;

    .register_box {
      width: 45%;
      position: relative;
      left: 50%;
      transform:translateX(-50%);
      border: 2px solid #F4B309;
      border-radius: 20px;
      margin-bottom: 100px;

      .handle-register {
        background-color: #F4B309;
        color: #FFFFFF;
        width: 18%;
        border: none;
        border-radius: 13px;
        padding: 10px 0px;
        margin: 30px 0 60px 0;
      }

      .register_box_title {
        h3 {
          font-size: 28px;
          font-weight: 800;
          padding: 30px 0 10px 0;
        }
      }

      .register_box_form {
        width: 65%;
        margin: auto;

        .register_form_ele {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;

          label {
            text-align: left;
            font-size: 18px;
            font-weight: 500;
          }

          input {
            padding: 10px;
            border: 1px solid #F4B309;                   
            border-radius: 10px;
          }

        }
      }

      .login_button {
        width: 20%;
        margin: 50px 0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}



// .auth-register {
//   margin-left: 12px;
//   .auth-register-btn {
//     border-radius: 25px;
//     // height: 59px;
//     width: 150px;
//     background-color: var(--orange-home);
//     border: 1px solid var(--orange-home);
//     font-size: 18px;
//     cursor: pointer;
//     padding: 12px 0;
//     color: white;
//     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   }
// }
// .handle-register {
//   margin-top: 40px;
//   height: 39px;
//   border-radius: 5px;
//   width: 99px;
//   cursor: pointer;
//   position: relative;
//   color: white;
//   text-align: center;
//   p {
//     padding-top: 7px;
//     font-size: 16px;
//     font-weight: 500;
//   }
//   background-color: var(--orange-home);
// }

// .handle-cancel-register {
//   margin-top: 40px;
// }
// // .MuiBox-root {
// //   .box-main-login {
// //     height: 100%;
// //     .box-main-form {
// //       // margin-top: 50px;
// //       .box-main-phone {
// //         display: grid;
// //         width: 100%;
// //         grid-template-columns: 1fr 1fr;
// //         gap: 40px;
// //         input {
// //           width: 100%;
// //         }
// //       }
// //     }
// //   }
// // }

// .verify-form {
//   // padding: 30px;
//   position: relative;
//   img {
//     width: 21px;
//     margin-bottom: 20px;
//   }
//   &-btn {
//     margin-top: 20px;
//   }
//   &-noti {
//     // float: right;
//     .MuiSnackbar-root {
//       padding: 200px;
//     }
//   }
// }

// .FormSignUp {
//   &__Input__Verify {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 30px;
//     padding: 0px 50px;

//     input {
//       width: 45px;
//       height: 45px;
//       text-align: center;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//     &__I {
//       border-bottom: 1px solid #369313;
//       color: #222222;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 45px;
//       height: 45px;
//     }
//   }
// }
