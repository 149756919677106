.header-detail-body{
    display: grid;
    height: 114px;
    justify-content: space-around;
    align-items: center;
    grid-template-columns: 0.5fr 1.5fr 0.3fr;
}

.header-detail-nav{
    width: 60%;
    height: 57px;
    margin-bottom: 30px;
    padding: 30px 0px 0px 90px;
    // justify-content: space-between;
    // display: flex;
    align-items: center;
}