.furnitures {
  &_container {
    h2 {
      width: 85%;
      margin: auto;
      text-align: left;
      font-weight: 700;
      font-size: 24px;
      color: #F4B309;
      margin-bottom: 30px;
    }
  }
}