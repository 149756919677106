.card-supplier-item {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    .image-card {
        flex: 3;
        border: 1px solid #A3A3A3;
        border-radius: 10px;

        img {
            width: 100%;
            border-radius: 10px;

        }
    }

    .card-content {
        flex: 7;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .supplier-name {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 10px;
        }

        .supplier-overlay {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #838383;
            margin-top: 10px;
        }

        .supplier-decs {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            visibility: visible;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .show-more {
            background: linear-gradient(93.58deg, #F4B309 0%, #FFE600 100%);
            border: none;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
            margin: 0;
            padding: 5px 20px;
            border-radius: 8px;
        }

        .show-more:hover {
            background: #FFFFFF;
            color: #F4B309;
            border: 1px solid #F4B309;
            transition: 1.1s;
        }
    }
}