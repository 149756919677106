.login {
  
  .login_content {
    width: 100%;
    padding: 100px 0;

    .login_box {
      width: 45%;
      position: relative;
      left: 50%;
      transform:translateX(-50%);
      border: 2px solid #F4B309;
      border-radius: 20px;

      .login_box_title {
        h3 {
          font-size: 28px;
          font-weight: 800;
          padding: 30px 0 10px 0;
        }
      }

      .login_box_form {
        width: 65%;
        margin: auto;

        .login_form_ele {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          label {
            text-align: left;
            font-size: 20px;
            font-weight: 500;
          }

          input {
            padding: 10px;
            border: 1px solid #F4B309;                   
            border-radius: 10px;
          }

        }
      }

      .password {
        width: 65%;
        margin: auto;
        display: flex;
        justify-content: space-between;

        .password-check {

          label {
            font-size: 18px;
          }
        }

        .password-forgot {
          font-size: 18px;
          
          a {
            color: #2F0565;
          }
        }
        
      }

      .login_link {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        
        .login_link_child {
          margin: 0 10px;
        }
      }

      .link_register {
        margin: 20px 0;
        p {
          font-size: 18px;
          a {
            font-weight: 400;
            color: #F4B309;
          }
        }
      }

      .login_button {
        width: 20%;
        margin: 50px 0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

// .auth-login {
//   margin-right: 0px;
//   &-btn {
//     border-radius: 25px;
//     // height: 59px;
//     width: 150px;
//     background-color: white;
//     border: 1px solid var(--orange-home);
//     font-size: 18px;
//     cursor: pointer;
//     padding: 12px 0;
//     color: var(--orange-home);
//     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   }
// }

// .MuiBox-root {
//   .box-main-login-normal {
//       display: flex;
//       justify-content: flex-end;
//     .box-main-login-cancel {
//       background: #dadada;
//       cursor: pointer;
//       margin-right: 13px;
//       height: 40px;
//       width: 86px;
//       font-weight: 500;
//       font-size: 16px;
//       text-align: center;
//       padding-top: 7px;
//       border-radius: 6px;
//     }

//   }
//   .box-main-login {
//     // height: 100px;
//     width: 100%;
//     height: 100%;
//     // background-color: rgb(243, 221, 221);
//     margin-top: -25px;
//     padding: 0px 5%;
//     .box-main-login-social {
//       display: flex;
//       justify-content: space-around;
//       align-items: center
//     }
//     .box-main-title {
//       p {
        // font-size: 20px;
        // font-weight: 500;
//       }
//       width: 100%;
//       height: 25px;
//       display: flex;
//       // justify-content: center;
//       // border-bottom: 2px solid #C4C4C4;
//       ;
//     }
//     .box-main-form {
//       input {
//         width: 100%;
//         padding-left: 15px;
//         height: 40px;
//         border: 1px solid #c4c4c4;
//         border-radius: 5px;
//       }
//       // label {
//       //   margin: 20px 0px 5px 0px;
//       // }
//       .box-main-form-forget {
//         color: var(--orange-home);
//         // margin-top: 40px;
//       }
//     }
//     .box-main-or {
//       text-align: center;
//       font-size: 16px;
//       font-weight: 600;
//       margin-top: 20px;
//     }
//   }
// }
