.design_banner {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }

  .design_banner_content {
    position: absolute;
    padding: 20px;
    width: 45%;
    left: 10%;
    bottom: 15%;
    background: rgba(255, 255, 255, 0.75);
    text-align: left;

    span {
      font-weight: 600;
      font-size: 24px;  
    }

    p {
      font-weight: 500;
      font-size: 16px;
      margin-top: 25px;
    }
  }
}