.Container{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .title{
    font-size: 20px;
    font-weight: 500;
    align-self: center;
  }
  .Right{
    display: flex;
    gap: 8px;
    .more{
      padding: 10px 16px 5px 16px;
      background: rgba(0, 104, 137, 0.1);
      border-radius: 5px;
    }
    .buttonAdd{
      background-color: #006889;
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 12px 16px;
      border-radius: 5px;
      color: #fff;
    }
  }
}