.material_card {
  width: calc(100% / 6 - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);

  &_img {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .material_card_name {
      font-weight: 600;
      font-size: 18px;
      width: 100%;
      text-align: left;
      margin: 20px 0 10px 0;
    }

    .material_card_supplier {
      font-weight: 700;
      font-size: 14px;
      text-align: left;
    }

    .material_card_button {
      display: flex;
      justify-content: right;

      .material_card_button_click {
        margin: 15px 0;
        font-size: 14px;
      }
    }

    .material_card_icon {
      text-align: right;
      color: #FF0000;
    }
  }
}

.fu_card:hover {
  transform: scale(1.08);
  transition: 0.5s;
}