.box-form{
  display: block;
  width: 60%;
  position: absolute;
  top: 50%;
  /* margin-right: -50%; */
  transform: translate(-50%, 10%);
  left: 50%;
  .buttonCreate{
    background-color: #006889;
    padding: 8px 16px;
    color: #fff;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
    float: right;
  }
}
