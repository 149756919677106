.Header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  //height: 98.4px;
  .left{
    display:flex;
    align-items: center;
    margin-left: 20px;
    p{
      margin: 0;
    };
  }
  .right{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
}